<template>
    <div class="personpage">
        <div class="my-card peoplelist-namecard">
            <div>
                <van-image
                    v-if="!user.userPictureurl"
                    round
                    width="5rem"
                    height="5rem"
                    fit="cover"
                    src="https://img.yzcdn.cn/vant/cat.jpeg"
                    style="margin-right: 1rem"
                />
                <van-image
                    v-else
                    round
                    width="5rem"
                    height="5rem"
                    fit="cover"
                    :src="user.userPictureurl"
                    style="margin-right: 1rem"
                />
            </div>
            <div class="my-font-md">
                <div class="my-font-bold my-font-lg peoplelist-namecard-up">
                    <span>{{ user.userNickname }}</span>
                </div>
                <div class="peoplelist-namecard-right">
                    <span>{{ user.userDirection }}</span>
                </div>
            </div>
        </div>
        <div
            v-if="user.userIntroduction"
            class="my-card my-font-md peoplelist-introduction"
            v-html="user.userIntroduction"
        />
        <div v-else class="my-card my-font-md peoplelist-introduction">
            暂无个人介绍
        </div>
    </div>
</template>

<script>
import { getUserById } from "@/api/common";
export default {
    components: {},
    data() {
        return {
            id: "",
            user: {
                role: {
                    roleType: "",
                },
            },
        };
    },
    computed: {},
    created() {},
    mounted() {
        this.getId();
        this.getUserById();
    },
    methods: {
        getRole(text) {
            return text.slice(-1, 1);
        },
        getId() {
            this.id = this.$route.params.id;
        },
        getUserById() {
            const data = {
                userId: this.id,
            };
            getUserById(data).then((res) => {
                if (res.code === 20000) {
                    this.user = res.data;
                }
            });
        },
    },
};
</script>

<style scoped>
.peoplelist-namecard {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.peoplelist-namecard-up {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 0.5rem;
}

.peoplelist-namecard-right {
    display: flex;
    flex-direction: column;
}

.peoplelist-introduction {
    margin-top: 1rem;

    line-height: 1.5rem;
}

/deep/ .peoplelist-introduction h1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/deep/ .peoplelist-introduction h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/deep/ .peoplelist-introduction h3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/deep/ .peoplelist-introduction h4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/* /deep/ .peoplelist-introduction code {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
} */

/deep/ .peoplelist-introduction p {
    /* 段间距 */
    margin-bottom: 1rem;

    max-width: 1278px;
}

/deep/ .peoplelist-introduction table {
    border-top: 1px solid #dcdee2;
    border-left: 1px solid #dcdee2;
}

/deep/ .peoplelist-introduction table td,
table th {
    border-bottom: 1px solid #dcdee2;
    border-right: 1px solid #dcdee2;
    padding: 3px 5px;
}

/deep/ .peoplelist-introduction table th {
    border-bottom: 2px solid #dcdee2;
    border-right: 1px solid #dcdee2;
    text-align: center;
}

/deep/ .peoplelist-introduction blockquote {
    display: block;
    border-left: 8px solid #66b5f3;
    padding: 5px 10px;
    margin: 10px 0;
    line-height: 1.4;
    font-size: 100%;
    background-color: #f1f1f1;
}

/deep/ .peoplelist-introduction code {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    background-color: #f1f1f1;
    border-radius: 3px;
    padding: 3px 5px;
    margin: 0 3px;
}

/deep/ .peoplelist-introduction pre code {
    display: block;
}

/deep/ .peoplelist-introduction ul,
ol {
    margin: 10px 0 10px 20px;
}
</style>
